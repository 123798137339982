import styled from "styled-components";
import {useContext} from "react";
import {AppointmentDetailsContext} from "../contexts/AppointmentDetailsContext";

function TimeSlot({start, end, ...props}) {
    const {appointmentData, setAppointmentData} = useContext(AppointmentDetailsContext);

    const setAppointmentSlotData = () => {
        setAppointmentData({...appointmentData, start, end, doctor_id: props.doctorId, doctor_name : props.doctorName, department_name: props.departmentName});
    }

    return (
        <Slot className={appointmentData.doctor_id === props.doctorId && appointmentData.start === start && appointmentData.end === end ? 'selected' : '' } onClick={() => setAppointmentSlotData()}>
            <span>{start}</span>
        </Slot>
    )
}

const Slot = styled.div`
  background-color: #fff;
  border: 1px solid #dbe2ed;
  border-radius: 5px;
  height: 35px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  font-size: 14px;
  cursor: pointer;

  &.selected, &:hover {
    background-color: #068f7a;
    border-color: #047e69;
    color: #fff;
  }
`;

export default TimeSlot;


