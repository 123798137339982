import Hero from "../components/Hero";
import {useEffect} from "react";

function Home() {
      return (
        <>
            <Hero/>
        </>

    )
}

export default Home;