import styled from "styled-components";
import {useEffect} from "react";
import Doctor from "./Doctor";
import dayjs from "dayjs";

function SearchResults({department, date, doctors}) {
    return (
        <Card className='border border-[#c4cfe3]/70 shadow-md shadow-[#c4cfe3]/20 pt-7 px-0 mt-6'>
            <CardTitle>Pentru specialitatea <b>{department}</b>, în data de <b>{dayjs(date).format('DD.MM.YYYY')}</b> am găsit următoarele ore disponibile</CardTitle>

            {doctors.map((doctor) => {
               return <Doctor className='mt-5' key={doctor.doctor_id} {...doctor} departmentName={department} />
            })}
        </Card>
    )
}

const Card = styled.div`
  background: #fff;
  border-radius: 5px;
  padding: 40px 30px;
  box-shadow: 0px 13px 40px -20px rgb(7 29 43 / 29%) !important;
  margin-bottom: 30px;
`

const CardTitle = styled.h3`
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 30px;
  color: #151515;
  
  b {
    font-weight: 600;
    color: #068f7a;
  }
`

export default SearchResults;