import {Container, Text} from "@mantine/core";
import {HiCheck} from "react-icons/hi";
import {useEffect} from "react";

function AppointmentCanceled() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Container className='medisharp-confirm-container-vh' size={420} my={40}>
                <>
                    <HiCheck className='medisharp-confirmed-icon'/>
                    <h1 className='confirm-page-title'>
                        Programarea a fost anulată
                    </h1>

                    <Text color="dimmed" size="sm" align="center" mt={5}>
                        Un email de confirmare a fost trimis pe adresa de email furnizată.
                    </Text>
                </>
            </Container>
        </>
    )
}

export default AppointmentCanceled;
