import { createStyles, Container, Group, Anchor } from '@mantine/core';
import {Link, NavLink} from "react-router-dom";
import logo from '../logo-grey.png'


const useStyles = createStyles((theme) => ({
    footer: {
        marginTop: 120,
        borderTop: `1px solid ${
            theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
        }`,
        backgroundColor: '#fff'
    },

    inner: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: theme.spacing.xs,
        paddingBottom: theme.spacing.xs,

        [theme.fn.smallerThan('xs')]: {
            flexDirection: 'column',
        },
    },

    links: {
        [theme.fn.smallerThan('xs')]: {
            marginTop: theme.spacing.md,
        },
    },
}));


export default function Footer({ links }) {
    const { classes } = useStyles();
    const items = links.map((link) => (
        <NavLink
            to={link.link}
            key={link.label}
            className='navlink-footer'
        >
            {link.label}
        </NavLink>
    ));
    return (
        <div className={classes.footer}>
            <Container size='lg' className={classes.inner}>
                <Link to="/" className="medisharp-logo medisharp-logo-footer" relative="path">
                    <img  src={logo} alt=""/>
                </Link>
                <Group className={classes.links}>{items}</Group>
            </Container>
        </div>
    );
}