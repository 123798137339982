import styled from "styled-components";
import {useContext, useEffect, useState} from "react";
import {AppointmentDetailsContext} from "../contexts/AppointmentDetailsContext";
import {useForm} from '@mantine/form';
import {TextInput, Button, Grid, Avatar, Select, Input} from '@mantine/core';
import dayjs from "dayjs";
import apiClient from "../services/api";
import {LoadingContext} from "../contexts/LoadingContext";
import {Link, useNavigate} from "react-router-dom";
import {FaUserMd} from 'react-icons/fa'
import {TbStethoscope} from 'react-icons/tb';
import {BsCalendar2} from 'react-icons/bs';
import {AiOutlineClockCircle} from 'react-icons/ai';
import config from "../config";
import Reaptcha from "reaptcha";
import {showNotification} from "@mantine/notifications";


const PatientDetails = ({}) => {

    let captchaRef = null;
    const navigate = useNavigate();
    const {setShowLoading} = useContext(LoadingContext);
    const [recaptchaError, setRecaptchaError] = useState(false);
    const [shouldResetRecaptcha, setShouldResetRecaptcha] = useState(false);
    // const [recaptchaResponse, setRecaptchaResponse] = useState('');
    const form = useForm({
        validateInputOnBlur: true,
        initialValues: {
            cnp: '1931026080027',
            last_name: 'Pintilei',
            first_name: 'Adrian',
            email: 'pintileigraphics@gmail.com',
            phone: '0743586301',
            pmode: 1,
            recaptcha_token: ''
        },
        // functions will be used to validate values at corresponding key
        validate: {
            cnp: (value) => (value.length !== 13  ? 'cnp must have exactly 13 digits' : null),
            last_name: (value) => (value.length < 2 ? 'last_name must have at least 2 letters' : null),
            first_name: (value) => (value.length < 2 ? 'first_name must have at least 2 letters' : null),
            email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Email invalid'),
            phone: (value) => (/^(\+4|)?(07[0-9]{1}[0-9]{1}|02[0-9]{2}|03[0-9]{2}){1}?(\s|\.|\-)?([0-9]{3}(\s|\.|\-|)){2}$/.test(value) ? null : 'Invalid phone number'),
            pmode: (value) => (value < 1 || value > 4 ? 'mode de prezentare invalid' : null)
        },
    });

    const handleSubmit = (values) => {
        setShowLoading(true);
        let formData = {
            ...values,
            ...appointmentData
        };
        addAppointment(formData);
    }

    const onChangeRecaptcha = (value) => {
        setAppointmentData({
            ...appointmentData,
            recaptcha_token: value
        })
    }

    const recaptchaErrorHandler = (value) => {
        setShouldResetRecaptcha(true);
    }

    useEffect(() => {
        if(shouldResetRecaptcha === true)  {
            if(appointmentData.recaptcha_token !== '') {
                captchaRef.reset();
            }

            onChangeRecaptcha('');
            setShouldResetRecaptcha(false);
        }
    }, [shouldResetRecaptcha])


    const goBack = () => {
        setAppointmentData({...appointmentData, start: null, end: null});
    }

    const addAppointment = async (formData) => {
        try {
            const response = await apiClient.post('api/appointment/' + formData.department + '/' + formData.doctor_id + '/', formData);
            if (response.data) {
                setShowLoading(false);

                if (response.data.uuid) {
                    setAppointmentData({});
                    return navigate("/confirm-appointment/" + response.data.uuid);
                }
            }
        } catch (err) {
            if(err.response){
                if(err.response.data.errors && err.response.data.errors.recaptcha_token ) {
                    setShouldResetRecaptcha(true);
                    setRecaptchaError(true);
                }
            }

            setShowLoading(false);
            // setSlotsData([]);
        }
    }

    const {appointmentData, setAppointmentData} = useContext(AppointmentDetailsContext);


    return (

        <Grid grow>
            <Grid.Col span={6}>
                <Card className='mh-card'>

                    {/*{ JSON.stringify(appointmentData)}*/}
                    {/*{dayjs(appointmentData.date).format('DD-MM-YYYY')}*/}

                    <AppointmentDetailsOverview>
                        <h3>Detaliile programării</h3>

                        <div className="appointment-details-wrapper">
                            <div className="icon-wrapper">
                                <FaUserMd/>
                            </div>
                            <div>
                                <div className="appointment-info">Doctor</div>
                                <div className="appointment-info-name"><span> {appointmentData.doctor_name}</span></div>
                            </div>
                        </div>

                        <div className="appointment-details-wrapper">
                            <div className="icon-wrapper">
                                <TbStethoscope/>
                            </div>
                            <div>
                                <div className="appointment-info">Specialitatea</div>
                                <div className="appointment-info-name"><span>{appointmentData.department_name}</span>
                                </div>
                            </div>
                        </div>

                        <div className="appointment-details-wrapper">
                            <div className="icon-wrapper">
                                <BsCalendar2 className='calendar-icon'/>
                            </div>
                            <div>
                                <div className="appointment-info">Data</div>
                                <div className="appointment-info-name"><span>{dayjs(appointmentData.date).format('DD.MM.YYYY')}</span>
                                </div>
                            </div>
                        </div>


                        <div className="appointment-details-wrapper">
                            <div className="icon-wrapper">
                                <AiOutlineClockCircle/>
                            </div>
                            <div>
                                <div className="appointment-info">Ora</div>
                                <div className="appointment-info-name"><span>{appointmentData.start}</span>
                                </div>
                            </div>
                        </div>


                        <button
                            onClick={() => goBack()}
                            className="medisharp-transparent-button medisharp-sm-button"
                        > <span className="text-indigo-200" aria-hidden="true">
                                                &larr;
                                            </span> {' '}
                            Alege altă oră
                        </button>
                    </AppointmentDetailsOverview>


                </Card>
            </Grid.Col>

            <Grid.Col span={6}>
                <Card className='card-form-wrapper mh-card'>
                    <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
                        <Grid grow>
                            <Grid.Col span={6}>
                                <TextInput withAsterisk label="Nume"
                                           placeholder="Nume" {...form.getInputProps('last_name')} />
                            </Grid.Col>

                            <Grid.Col span={6}>
                                <TextInput withAsterisk  label="Prenume"
                                           placeholder="Prenume" {...form.getInputProps('first_name')} />
                            </Grid.Col>
                        </Grid>


                        <Grid grow>
                            <Grid.Col span={6}>
                                <TextInput withAsterisk mt="sm" type="email" label="Email"
                                           placeholder="Email" {...form.getInputProps('email')} />
                            </Grid.Col>

                            <Grid.Col span={6}>
                                <TextInput withAsterisk type="tel" mt="sm" label="Telefon"
                                           placeholder="Telefon" {...form.getInputProps('phone')} />
                            </Grid.Col>
                        </Grid>

                        <TextInput withAsterisk type="text" mt="sm" label="Cod numeric personal"
                                   placeholder="CNP" {...form.getInputProps('cnp')} />

                        <Select
                            mt="sm"
                            withAsterisk
                            label="Mod de prezentare"
                            placeholder="Alegeti mod de prezentare"
                            data={[
                                {value: 1, label: 'Bilet de trimitere'},
                                {value: 2, label: 'Bilet de externare'},
                                {value: 3, label: 'Control '},
                                {value: 4, label: 'Cu plată la casierie'},
                            ]}
                            {...form.getInputProps('pmode')}
                        />

                        <Reaptcha sitekey="6Ldwh28jAAAAANxObmHxAYuskbStGrLE1y4JzRhD"
                                  hl='ro'
                                  onError={() => recaptchaErrorHandler()}
                                  onExpire={() => onChangeRecaptcha('')}
                                  ref={e => (captchaRef = e)}
                                  onVerify={(recaptchaResponse) => onChangeRecaptcha(recaptchaResponse)} />
                        {recaptchaError && <Input.Error>Eroare captcha</Input.Error>}
                        <button
                           type="submit"
                            className="medisharp-button medisharp-submit-button mtop-30"
                        >Finalizează programarea {' '}
                            <span className="text-indigo-200" aria-hidden="true">
                                            &rarr;
                                        </span>
                        </button>
                    </form>
                </Card>
            </Grid.Col>
        </Grid>


    )
}
const Card = styled.div`
  background: #fff;
  border-radius: 5px;
  padding: 40px 30px;
  box-shadow: 0px 13px 40px -20px rgb(7 29 43 / 29%) !important;
  margin-bottom: 30px;
  
  &.mh-card {
    min-height: 390px;
  }
`

const CardTitle = styled.h3`
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 30px;
  color: #151515;

  b {
    font-weight: 600;
    color: #068f7a;
  }
`

const AppointmentDetailsOverview = styled.div`
  background: #068f7a;
  background-size: cover;
  background-position: center center;
  padding: 15px;
  border-radius: 5px;
  color: #fff;

  h3 {
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 36px;
  }

  .appointment-details-wrapper {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    color: rgb(255, 255, 255);
    margin-bottom: 25px;
    border-bottom: 1px dashed rgba(255,255,255,0.3);
    padding-bottom: 5px;
    
    .icon-wrapper {
      width: 24px;
      margin-right: 24px;
      text-align: center;

      svg {
        font-size: 24px;
      
        &.calendar-icon {
          font-size: 20px;
        }
      }
    }

    .appointment-info {
      font-size: 12px;
      line-height: 1.55;
      text-decoration: none;
      color: rgb(255, 255, 255, 0.5);
    }

    .appointment-info-name span {
      font-size: 14px;
      font-weight: 300;
    }
  }
`

export default PatientDetails;