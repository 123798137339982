import {useNavigate, useParams} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import apiClient from "../services/api";
import {useForm} from "@mantine/form";
import {RxMobile} from 'react-icons/rx'
import Countdown, {zeroPad} from "react-countdown";

import {
    TextInput,
    Grid,
    Container,
    Text,
    Paper, Alert,
} from "@mantine/core";
import {LoadingContext} from "../contexts/LoadingContext";
import styled from "styled-components";
import dayjs from "dayjs";
import {showNotification} from "@mantine/notifications";

const MINUTES_UNTIL_EXPIRE = 900000; //15 MINUTES * 60 * 1000

function ConfirmAppointment() {
    const params = useParams();
    const [phoneNumber, setPhoneNumber] = useState('');
    const [createdAt, setCreatedAt] = useState(null);
    const [expired, setExpired] = useState(false);
    const {setShowLoading} = useContext(LoadingContext);
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0)
        getPhoneNumber(params.uuid);
        document.body.classList.add('appointment-layout')
        return () => {
            document.body.classList.remove('appointment-layout')
        }
    }, [])

    const getPhoneNumber = async (uuid) => {
        try {
            const response = await apiClient.get('api/appointment/phone-number/' + params.uuid);

            if (response.data && response.data.phone) {

                if(response.data.verified === 1) {
                    return navigate("/appointment-confirmed");
                }

                setPhoneNumber(response.data.phone);
                setCreatedAt(dayjs(response.data.created_at).valueOf() + MINUTES_UNTIL_EXPIRE);
            }
        } catch (err) {
            return navigate("/not-found");
        }

    }


    const form = useForm({
        validateInputOnBlur: true,
        initialValues: {code: ''},
        // functions will be used to validate values at corresponding key
        validate: {
            code: (value) => (value.length !== 6 ? 'Codul trebuie sa fie format din exact 6 cifre' : null),
        },
    });


    const confirm = async (formData) => {
        setShowLoading(true);

        try {
            const response = await apiClient.post('api/appointment/confirm/' + params.uuid, formData);
            if (response.data) {

                if(response.data.message === true) {
                    setShowLoading(false);
                    return navigate("/appointment-confirmed");
                }

                setShowLoading(false);
            }
        } catch (err) {
            let statusCode = err.response.status

            switch (statusCode) {
                case 429:
                    showNotification({
                        id: 'invalid-code',
                        color: 'red',
                        title: 'Prea multe incercari esuate',
                        message: 'Va rugam sa reincercati din nou intr-un minut',
                        autoClose: false,
                        disallowClose: false,
                    });
                    break;
                default:
                    showNotification({
                        id: 'invalid-code',
                        color: 'red',
                        title: 'Cod incorect',
                        message: 'Va rugam sa reincercati',
                        autoClose: true,
                        disallowClose: false,
                    });
            }

            setShowLoading(false);
        }
    }

    const renderer = ({minutes, seconds, completed}) => {
        if (completed) {
            // Render a complete state
            return <Alert title="Ghinion!" color="red" mt="lg" variant="filled">
                Timpul a expirat. Programarea a fost stearsa in mod automat.
            </Alert>

        } else {
            // Render a countdown
            return (
                <span className='medisharp-countdown'>
                    {zeroPad(minutes)}:{zeroPad(seconds)}
                </span>
            );
        }
    };


    return (
        <>
            <Container className='medisharp-confirm-container-vh' size={420} my={40}>
                {!expired &&

                    <>
                        <RxMobile className='medisharp-mobile-icon'/>
                        <h1 className='confirm-page-title'>
                            Introdu codul primit prin SMS
                        </h1>
                        <Text color="dimmed" size="sm" align="center" mt={5}>
                            Aveti 15 minute la dispozitie pentru a introduce codul trimis la <strong
                            className='medisharp-sent-phone-sms'>{phoneNumber}</strong>, in caz contrar, programarea va
                            fi stearsa.
                        </Text>
                    </>
                }

                {
                    createdAt &&
                    <Countdown onComplete={() => setExpired(true)} date={createdAt} zeroPadTime={2}
                               renderer={renderer}/>
                }
                {!expired &&
                    <Paper className='medisharp-confirm-appointment' withBorder shadow="md" p={30} mt={30}
                           radius="md">
                        <form onSubmit={form.onSubmit((values) => confirm(values))}>
                            <TextInput className='mh-50' withAsterisk label="Cod SMS"
                                       placeholder="Cod SMS" {...form.getInputProps('code')} />
                            <button
                                type="submit"
                                className="medisharp-button medisharp-submit-button mtop-20"
                            >Confirmare programare {' '}
                            </button>
                        </form>
                    </Paper>

                }


            </Container>

        </>
    )
}

export default ConfirmAppointment;
