import SearchWrapper from "./SearchWrapper";
import {useEffect} from "react";

function Appointment() {

    useEffect(() => {
        document.body.classList.add('appointment-layout')
        return () => {
            document.body.classList.remove('appointment-layout')
        }
    }, [])
    return <>
        <SearchWrapper/>
    </>
}

export default Appointment;