import {useNavigate, useParams} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import apiClient from "../services/api";
import {useForm} from "@mantine/form";
import {RxMobile} from 'react-icons/rx'
import {MdFreeCancellation} from 'react-icons/md';
import Countdown, {zeroPad} from "react-countdown";
import Reaptcha from "reaptcha";

import {
    TextInput,
    Grid,
    Container,
    Text,
    Paper, Alert, Input,
} from "@mantine/core";
import {LoadingContext} from "../contexts/LoadingContext";
import {showNotification} from "@mantine/notifications";

function CancelAppointment() {
    let captchaRef = null;
    const params = useParams();
    const [phoneNumber, setPhoneNumber] = useState('');
    const [createdAt, setCreatedAt] = useState(null);
    const [expired, setExpired] = useState(false);
    const [recaptchaError, setRecaptchaError] = useState(false);
    const [shouldResetRecaptcha, setShouldResetRecaptcha] = useState(false);
    const {setShowLoading} = useContext(LoadingContext);
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0)

        document.body.classList.add('appointment-layout')
        return () => {
            document.body.classList.remove('appointment-layout')
        }
    }, [])

    useEffect(() => {
        if(shouldResetRecaptcha === true)  {
            captchaRef.reset();

            onChangeRecaptcha('');
            setShouldResetRecaptcha(false);
        }
    }, [shouldResetRecaptcha])

    const onChangeRecaptcha = (value) => {
        form.setFieldValue('recaptcha_token', value);
    }

    const recaptchaErrorHandler = (value) => {
        setShouldResetRecaptcha(true);
    }


    const form = useForm({
        validateInputOnBlur: true,
        initialValues: {cnp: '', code: '',  recaptcha_token: ''},
        // functions will be used to validate values at corresponding key
        validate: {
            cnp: (value) => (value.length !== 13  ? 'cnp must have exactly 13 digits' : null),
            code: (value) => (value.length < 9 ? 'Codul trebuie sa fie format din exact 9 caractere' : null),
        },
    });


    const cancelAppointment = async (formData) => {
        setShowLoading(true);

        try {
            const response = await apiClient.post('api/appointment/cancel', formData);
            if (response.data) {

                if(response.data.message === true) {
                    setShowLoading(false);
                    return navigate("/appointment-canceled");
                }

                setShowLoading(false);
            }
        } catch (err) {
            let statusCode = err.response.status

            if(err.response){
                if(err.response.data.errors && err.response.data.errors.recaptcha_token ) {
                    setShouldResetRecaptcha(true);
                    setRecaptchaError(true);
                }else {
                    setRecaptchaError(false);
                }
            }

            switch (statusCode) {
                case 429:
                    showNotification({
                        id: 'invalid-code',
                        color: 'red',
                        title: 'Prea multe incercari esuate',
                        message: 'Va rugam sa reincercati din nou intr-un minut',
                        autoClose: false,
                        disallowClose: false,
                    });
                    break;
                default:
                    showNotification({
                        id: 'invalid-code',
                        color: 'red',
                        title: 'Ceva nu a functionat',
                        message: 'Va rugam sa reincercati',
                        autoClose: true,
                        disallowClose: false,
                    });
            }

            setShowLoading(false);
        }
    }

    return (
        <>
            <Container className='medisharp-confirm-container-vh' size={420} my={40}>
                    <>
                        <MdFreeCancellation      className='medisharp-mobile-icon'/>
                        <h1 className='confirm-page-title'>
                            Introduceti CNP-ul si numarul de programare
                        </h1>
                        <Text color="dimmed" size="sm" align="center" mt={5}>
                            Pe baza CNP-ului si a numarului de programare de forma <strong>({process.env.REACT_APP_HOSPITAL_PREFIX}XXXXXX)</strong> primit pe email si SMS la momentul completarii vom putea sa anulam aceasta programare.
                        </Text>
                    </>

                    <Paper className='medisharp-confirm-appointment' withBorder shadow="md" p={30} mt={30}
                           radius="md">
                        <form onSubmit={form.onSubmit((values) => cancelAppointment(values))}>
                            <TextInput className='mh-50' withAsterisk label="CNP"
                                       placeholder="Cod numeric personal" {...form.getInputProps('cnp')} />
                            <TextInput className='mh-50' mt={15} withAsterisk label="Numar programare"
                                       placeholder={process.env.REACT_APP_HOSPITAL_PREFIX + 'XXXXXX'} {...form.getInputProps('code')} />

                            <Reaptcha sitekey="6Ldwh28jAAAAANxObmHxAYuskbStGrLE1y4JzRhD"
                                      hl='ro'
                                      onError={() => recaptchaErrorHandler()}
                                      onExpire={() => onChangeRecaptcha('')}
                                      ref={e => (captchaRef = e)}
                                      onVerify={(recaptchaResponse) => onChangeRecaptcha(recaptchaResponse)} />
                            {recaptchaError && <Input.Error>Eroare captcha</Input.Error>}
                            <button
                                type="submit"
                                className="medisharp-button medisharp-submit-button mtop-20"
                            >Anulare programare {' '}
                            </button>
                        </form>
                    </Paper>


            </Container>

        </>
    )
}

export default CancelAppointment;
