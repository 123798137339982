import {Link} from "react-router-dom";
import {Container} from "@mantine/core";
// import heroImg from '../../public/assets/images/hero.jpg';
import styled from "styled-components";
import Appointment from "./Appointment";
import {AiOutlineThunderbolt} from "react-icons/ai";
import {BiCheckDouble} from "react-icons/bi"
import {MdOutlineFreeCancellation} from "react-icons/md"
import config from "../config";



export default function Hero({startAppointment}) {

    return (
        <HeroWrapper>
            <Container size='lg'>
                <main>
                    <div className="relative px-6 lg:px-8">
                        <div className="mx-auto max-w-4xl pt-20 pb-32 sm:pt-48 sm:pb-40">
                            <div>
                                <Headline>
                                    Programează o consultație în Spitalul C.F Sibiu
                                </Headline>

                                <Subheadline>
                                    Selectează specialitatea, medicul și intervalul orar în care vrei să te prezinți
                                </Subheadline>


                                <div className="mt-8 flex gap-x-4 sm:justify-center">
                                    <Link
                                        to="/appointment"
                                        className="medisharp-button medisharp-pulse-green"
                                    >
                                        Începe acum {' '}
                                        <span className="text-indigo-200" aria-hidden="true">
                                            &rarr;
                                        </span>
                                    </Link>

                                </div>


                                <Advantages>
                                    <div>
                                        <AiOutlineThunderbolt/>
                                        <span>Simplu și rapid, 100% online</span>
                                    </div>

                                    <div>
                                        <BiCheckDouble/>
                                        <span>Primești confirmarea instant prin SMS</span>
                                    </div>

                                    <div>
                                        <MdOutlineFreeCancellation/>
                                        <span>Poți anula programarea fără dureri de cap</span>
                                    </div>
                                </Advantages>

                            </div>
                        </div>
                    </div>
                </main>
            </Container>
        </HeroWrapper>

    )
}

const Headline = styled.h1`
  font-weight: 600;
  font-size: 48px;
  letter-spacing: 0;
  line-height: 70px;
  max-width: 60%;
  color: #151515;
  @media (max-width: 640px) {
    line-height: 1.3;
    font-size: 24px;
    width: 100%;
    max-width: 100%;
  }
`

const Subheadline = styled.p`
  margin-top: 42px;
  color: #97a0a8;
  font-weight: 300;
  font-size: 21px;
  max-width: 50%;
  line-height: 32px;
  margin-bottom: 30px;
  @media (max-width: 640px) {
    max-width: 100%;
    color: #151515;
  }
`

const Advantages = styled.div`
  display: flex;
  max-width: 100%;
  margin-top: 60px;
  padding: 30px 5px;
  box-shadow: 0px 13px 40px -20px rgb(7 29 43 / 29%) !important;
  background-color: #fff;
  border-radius: 10px;
  box-sizing: border-box;
  @media (max-width: 640px) {
    max-width: 100%;
    flex-direction: column;
  }

  div {
    flex: 1;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 640px) {
      margin-bottom: 10px;
    }

    svg {
      width: 32px;
      height: 32px;
      padding: 15px;
      background-color: #fccd00;
      border-radius: 50%;
      margin-bottom: 15px;
      color: #fff;
    }

    span {
      display: block;
      max-width: 200px;
      color: #97a0a8;
      font-weight: 400;
      line-height: 24px;
    }
  }
`

const HeroWrapper = styled.div`
  padding-top: 150px;
  position: relative;
  min-height: calc(100vh - 210px);

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 45%;
    background: url('assets/images/hero-1.jpg');
    background-size: cover;
    background-position: center right;
    z-index: -1;
    border-bottom-left-radius: 30px;
    @media (max-width: 640px) {
      opacity: 0.5;
    }
  }
  
  
`