import {Grid} from "@mantine/core";
import {useEffect} from "react";
import TimeSlot from "./TimeSlot";

function Doctor(props) {
    useEffect(() => {
        console.log(props)
    }, [])


    const getInitials = function (string) {
        let names = string.split(' '),
            initials = names[0].substring(0, 1).toUpperCase();

        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
    };

    return (
        <Grid grow className='medisharp-doctor-wrapper'>
            <Grid.Col className='medisharp-doctor-details' span={3}>
                <div className='doctor-avatar'>
                    {getInitials(props.doctor)}
                </div>
                <br/>
                <span className='block mt-3'>Dr. {props.doctor}</span>
            </Grid.Col>
            <Grid.Col className="medisharp-slots-wrapper" span={9}>
                {
                    props.slots.map((slot) => {
                        return <TimeSlot key={props.doctor.doctor_id + '-' + slot.start} {...slot} doctorName={props.doctor} departmentName={props.departmentName} doctorId={props.doctor_id}/>
                    })
                }
            </Grid.Col>
        </Grid>
    )
}

export default Doctor;